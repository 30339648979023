export enum TermsDocumentType {
  COOKIES_POLICY = 'cookies-policy',
  INTERNAL_RULES = 'internal-rules',
  LEGAL_MENTIONS = 'legal-mentions',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
}

export type TermsDocument = {
  type: TermsDocumentType;
  locale: string;
  campus?: string;
};
