'use client';
import React from 'react';
import { useSearchParams } from 'next/navigation';
import { withSearchParams } from '@core-systems/utils';
import { Link } from '@design-system';

export type BackToLoginLinkProps = {
  locale: string;
  children: React.ReactElement;
};

export function BackToLoginLink({ children, locale }: BackToLoginLinkProps): React.JSX.Element {
  const currentSearchParams = useSearchParams();
  const backToLoginUrl = withSearchParams(`/${locale}/login`, currentSearchParams);

  return <Link href={backToLoginUrl}>{children}</Link>;
}
