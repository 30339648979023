import { NextResponse } from 'next/server';
import type { NextRequest } from 'next/server';
import { localeFromRequest } from '../i18n/server/locale/locale-from-request';
import { setCookieOnResponse } from '../i18n/server/set-cookie-on-response';

export function addLocaleMiddleware(request: NextRequest): NextResponse {
  const pathname = request.nextUrl.pathname;
  const locale = localeFromRequest(request);
  let response;
  if (!pathname.startsWith(`/${locale}/`) && pathname !== `/${locale}`) {
    const newUrl = new URL(String(request.nextUrl));
    // Replace any potential /en or /en-US with the new locale
    newUrl.pathname = pathname.replace(/^(?:(?:\/[a-z]{2})(?:-[a-z]{2})?)?(\/.*)?$/i, `${locale}$1`);
    response = NextResponse.redirect(newUrl);
  } else {
    response = NextResponse.next();
  }
  setCookieOnResponse(response, locale);
  return response;
}
