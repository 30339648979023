'use client';

import { useCookiesConsent } from '@core-systems/cookies';
import { useClientTranslations } from '@core-systems/i18n';
import { TermsDocumentType, useTerms } from '@core-systems/terms';
import { Button, Separator } from '@design-system';
import { cn } from '@utils';

type LegalFooterProps = {
  cookiesManagement?: boolean;
  cookiesPolicy?: boolean;
  privacyPolicy?: boolean;
  termsOfUse?: boolean;
  legalMentions?: boolean;
  internalRules?: boolean;
  className?: string;
};

export const LegalFooter = ({
  cookiesManagement = true,
  cookiesPolicy = true,
  privacyPolicy = true,
  termsOfUse = true,
  legalMentions = true,
  internalRules = false,
  className,
}: LegalFooterProps): JSX.Element => {
  const { t } = useClientTranslations('common');
  const { showConsentManager } = useCookiesConsent();
  const { openDocumentForReading } = useTerms();

  const legalEntries = [
    {
      isEnabled: cookiesManagement,
      text: t('legal.actions.manage-cookies'),
      action: showConsentManager,
    },
    {
      isEnabled: cookiesPolicy,
      text: t('legal.actions.view-cookies-policy'),
      action: (): void => openDocumentForReading(TermsDocumentType.COOKIES_POLICY),
    },
    {
      isEnabled: privacyPolicy,
      text: t('legal.actions.view-privacy-policy'),
      action: (): void => openDocumentForReading(TermsDocumentType.PRIVACY_POLICY),
    },
    {
      isEnabled: termsOfUse,
      text: t('legal.actions.view-terms-of-use'),
      action: (): void => openDocumentForReading(TermsDocumentType.TERMS_OF_USE),
    },
    {
      isEnabled: legalMentions,
      text: t('legal.actions.view-legal-mentions'),
      action: (): void => openDocumentForReading(TermsDocumentType.LEGAL_MENTIONS),
    },
    {
      isEnabled: internalRules,
      text: t('legal.actions.view-internal-rules'),
      action: (): void => openDocumentForReading(TermsDocumentType.INTERNAL_RULES),
    },
  ]
    .filter(({ isEnabled }) => isEnabled)
    .flatMap(({ action, text }, index) => [
      <Separator key={`separator-${index}`} orientation="vertical" className="hidden sm:flex" />,
      <Button
        variant="link"
        onClick={action}
        key={`entry-${index}`}
        className="md:leading-xs text-primary-border !p-0 font-normal normal-case md:text-xs"
      >
        {text}
      </Button>,
    ])
    .slice(1);

  return (
    <nav
      className={cn(
        'text-primary-border mx-auto flex w-full flex-row flex-wrap items-center justify-center',
        'my-lg px-md gap-md body-4',
        'sm:mb-xl sm:gap-lg sm:body-3',
        className,
      )}
    >
      {legalEntries}
    </nav>
  );
};
